<template>
	<div class="container">
		<div class="row header align-end">
			<div class="col-12">
				<backTo
					:link="'/admin/quizes'"
					><template #text>Back to Quiz</template></backTo
				>
				<h3 class="mt-5">Add New Question from Blank Template</h3>
			</div>
		</div>

		<div class="row new-course">
			<div class="col-12">

				<div class="brand-form">

					<v-row v-if="quizQuestionsLength !== 0" class="d-flex flex-column">
			<admin-default-subheader class="mb-4">
				Will this question be triggered by another question in this
				quiz?
			</admin-default-subheader>
			<v-radio-group v-model="trigger" class="radio-inline">
				<v-col class="col-2"
					><v-radio label="No" color="black" :value="false"></v-radio
				></v-col>
				<v-col class="col-2"
					><v-radio label="Yes" color="black" :value="true"></v-radio
				></v-col>
			</v-radio-group>



		</v-row>
		<v-row v-if="trigger === true">
			<InputSelect
				v-model="triggerQuestion"
				:items="quizQuestions"
				:itemText="'content'"
				:itemValue="'id'"
			>
			<template #label> <b>What is the trigger question?</b> </template>
			</InputSelect>



		</v-row>
		<v-row v-if="trigger === true">
			<InputSelect
				v-model="triggerResponse"
				:items="quizResponses"
				:itemText="'content'"
				:itemValue="'id'"
				label="What response(s) is the condition for this question?"
			></InputSelect>
		</v-row>
		<v-row class="">
			<v-col
				><AdminDefaultSubheader class="mb-4"
					>Question Details</AdminDefaultSubheader
				>
				<InputTextArea v-model="newQuestion">
					<template #label> <b>Question Text</b></template></InputTextArea
				>
			</v-col>
		</v-row>

		<v-row class="">
			<v-col>
				<NewQuestionResponse
					:responses="responses"
					:type="type"
					@setValue="$emit('setValue', $event)"
					@addNewResponses="$emit('addNewResponses')"
				>
				</NewQuestionResponse>
			</v-col>
		</v-row>

		<v-row v-if="!isQuestionEdited" class="mb-4">
			<v-col>
				<admin-default-subheader>
					Add this question to a Question Bank?
				</admin-default-subheader>
				<AdminDefaultDescription>
					If this is a triggered question, the parent question and all
					conditional questions attached to it will be added to the
					bank.
				</AdminDefaultDescription>
			</v-col>
		</v-row>
		<v-row v-if="!isQuestionEdited">
			<v-col>
				<v-radio-group v-model="wantToAddToBankQuestion" class="radio-inline">
					<v-col class="col-2"
						><v-radio
							label="No"
							color="black"
							:value="false"
						></v-radio
					></v-col>
					<v-col class="col-2"
						><v-radio
							label="Yes"
							color="black"
							:value="true"
						></v-radio
					></v-col>
				</v-radio-group>
				<InputSelect
					v-if="wantToAddToBankQuestion"
					v-model="bankQuestions"
					:items="bankQuetionsTypes"
					label="Select Question Bank"
				></InputSelect>
			</v-col>
		</v-row>
		<form-message v-if="message">{{ message }}</form-message>
		<v-row class="mt-16">
			<v-col class="d-flex justify-end">
				<v-btn
					v-if="!isQuestionEdited"
					class="admin-primary-button primary-contrast-background mr-4"
					:loading="isLoading"
					:disabled="isLoading"
					@click="goBack"
					>Back</v-btn
				>
				<v-btn
					v-if="isQuestionEdited"
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="saveQuestion"
					>Save</v-btn
				>
				<v-btn
					v-else
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="createNewQuestion"
					>Create</v-btn
				>
			</v-col>
		</v-row>

				</div>
			</div>
		</div>

	</div>
	<!-- <v-container class="main-container">
		<v-row>
			<v-col cols="8" class="pa-0">
				<backTo elClass="mb-10 d-block" :link="'/admin/quizes'"
					><template #text>Back to quizes</template></backTo
				>
				<admin-default-subheader class="mb-4">
					{{ quizName }}
				</admin-default-subheader>
				<admin-default-header
					>Add New Question from Blank Template</admin-default-header
				> </v-col
			><v-col cols="4" class="d-flex justify-end">
				<admin-default-subheader> 2 of 2</admin-default-subheader>
			</v-col>
		</v-row>
		<v-row v-if="quizQuestionsLength !== 0" class="d-flex flex-column">
			<admin-default-subheader class="mt-16 mb-4">
				Will this question be triggered by another question in this
				quiz?
			</admin-default-subheader>
			<v-radio-group v-model="trigger">
				<v-col
					><v-radio label="No" color="black" :value="false"></v-radio
				></v-col>
				<v-col
					><v-radio label="Yes" color="black" :value="true"></v-radio
				></v-col>
			</v-radio-group>
		</v-row>
		<v-row v-if="trigger === true">
			<InputSelect
				v-model="triggerQuestion"
				:items="quizQuestions"
				:itemText="'content'"
				:itemValue="'id'"
				label="What is the trigger question?"
			></InputSelect>
		</v-row>
		<v-row v-if="trigger === true">
			<InputSelect
				v-model="triggerResponse"
				:items="quizResponses"
				:itemText="'content'"
				:itemValue="'id'"
				label="What response(s) is the condition for this question?"
			></InputSelect>
		</v-row>
		<v-row class="mt-16">
			<v-col
				><AdminDefaultSubheader class="mb-4"
					>Question Details</AdminDefaultSubheader
				>
				<InputTextArea v-model="newQuestion">
					<template #label> Question Text</template></InputTextArea
				>
			</v-col>
		</v-row>

		<v-row class="mt-16">
			<v-col>
				<NewQuestionResponse
					:responses="responses"
					:type="type"
					@setValue="$emit('setValue', $event)"
					@addNewResponses="$emit('addNewResponses')"
				>
				</NewQuestionResponse>
			</v-col>
		</v-row>

		<v-row v-if="!isQuestionEdited" class="mt-16 mb-4">
			<v-col>
				<admin-default-subheader>
					Add this question to a Question Bank?
				</admin-default-subheader>
				<AdminDefaultDescription>
					If this is a triggered question, the parent question and all
					conditional questions attached to it will be added to the
					bank.
				</AdminDefaultDescription>
			</v-col>
		</v-row>
		<v-row v-if="!isQuestionEdited">
			<v-col>
				<v-radio-group v-model="wantToAddToBankQuestion">
					<v-col
						><v-radio
							label="No"
							color="black"
							:value="false"
						></v-radio
					></v-col>
					<v-col
						><v-radio
							label="Yes"
							color="black"
							:value="true"
						></v-radio
					></v-col>
				</v-radio-group>
				<InputSelect
					v-if="wantToAddToBankQuestion"
					v-model="bankQuestions"
					:items="bankQuetionsTypes"
					label="Select Question Bank"
				></InputSelect>
			</v-col>
		</v-row>
		<form-message v-if="message">{{ message }}</form-message>
		<v-row class="mt-16">
			<v-col class="d-flex justify-end">
				<v-btn
					v-if="!isQuestionEdited"
					class="admin-primary-button primary-contrast-background mr-4"
					:loading="isLoading"
					:disabled="isLoading"
					@click="goBack"
					>Back</v-btn
				>
				<v-btn
					v-if="isQuestionEdited"
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="saveQuestion"
					>Save</v-btn
				>
				<v-btn
					v-else
					class="admin-primary-button primary-contrast-background"
					:loading="isLoading"
					:disabled="isLoading"
					@click="createNewQuestion"
					>Create</v-btn
				>
			</v-col>
		</v-row>
	</v-container> -->
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultDescription from "@/components/AdminDefaultDescription.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import NewQuestionResponse from "./response-types/NewQuestionResponse.vue";
import BackTo from "@/components/BackTo.vue";
import InputSelect from "@/components/InputSelect.vue";
import { mapGetters } from "vuex";
import FormMessage from "@/components/FormMessage.vue";
import { get } from "@/util/requests/get";

export default {
	name: "QuizEditorAddNewQuestionStep2",
	props: [
		"template",
		"message",
		"quizName",
		"responses",
		"isTriggered",
		"question",
		"type"
	],
	components: {
		AdminDefaultSubheader,
		AdminDefaultDescription,
		AdminDefaultHeader,
		NewQuestionResponse,
		BackTo,
		InputSelect,
		FormMessage
	},
	computed: {
		...mapGetters(["isLoading"]),
		quizQuestionsLength() {
			if (this.quiz === null) {
				return 0;
			} else {
				return this.quiz?.questions.length;
			}
		},
		quizQuestions() {
			return this.quiz.questions.map(el => el.question);
		},
		quizResponses() {
			if (this.triggerQuestion === "") return;
			return this.quizQuestions.find(el => el.id === this.triggerQuestion)
				.responses;
		},
		isQuestionEdited() {
			return !!this.$route.query.edit;
		},
		trigger: {
			get() {
				return this.isTriggered;
			},
			set(val) {
				this.$emit("setValue", {
					key: "isTriggered",
					value: val
				});
			}
		},
		newQuestion: {
			get() {
				return this.question;
			},
			set(val) {
				this.$emit("setValue", {
					key: "question",
					value: val
				});
			}
		}
	},
	data() {
		return {
			quiz: null,
			wantToAddToBankQuestion: false,
			bankQuestions: "",
			bankQuetionsTypes: ["Master Bank"],
			triggerQuestion: "",
			triggerResponse: ""
		};
	},
	methods: {
		async valide() {
			this.$emit("setMessage", "");
			if (this.template === "file") {
				this.$emit(
					"setMessage",
					"Content from file is not available yet, please select basic template"
				);
				return false;
			}
			return true;
		},
		async goBack() {
			this.$emit("selectComponent", "QuizEditorAddNewQuestionStep1");
		},
		async createNewQuestion() {
			this.$emit("createQuestion");
		},
		async saveQuestion() {
			this.$emit("saveQuestion");
		},
		async getQuiz() {
			const response = await get(`/quiz/${this.$route.params.id}`);
			const {
				data: { data }
			} = response;
			this.quiz = data;
		}
	},
	updated() {
		this.$emit("setValue", {
			key: "triggerQuestion",
			value: this.triggerQuestion
		});
		this.$emit("setValue", {
			key: "triggerResponse",
			value: this.triggerResponse
		});
		this.$emit("setValue", {
			key: "wantToAddToBankQuestion",
			value: this.wantToAddToBankQuestion
		});
	},
	async created() {
		if (this.isQuestionEdited) {
			return;
		}
		await this.getQuiz();
	}
};
</script>

<style></style>
